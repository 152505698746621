<template>
    <v-card
        link
        :to="to"
        class="big-card-button"
    >
      <v-icon
        class="big-card-icon"
      >
        {{ this.icon }}
      </v-icon>
      <div
        class="big-card-title"
      >
        <slot></slot>
      </div>
    </v-card>
</template>

<script>
export default {
  name: 'SectionBtnCard',
  props: ['icon', 'to'],
};
</script>

<style scoped>
  .big-card-button {
    width: 200px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    text-align: center;
    color: #7fcfff;
  }

  .big-card-button:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 1);
  }

  .big-card-title {
    font-weight: bold;
    font-size: 20px;
    padding: 0;
    text-align: center;
  }

  .big-card-icon {
    color: #7fcfff;
    font-size: 7rem;
  }
</style>
