<template>
  <v-container>
    <v-row
      class="d-flex justify-center align-stretch"
    >
        <SectionBtnCard
            v-for="(menuItem, i) in menuItems"
            :key="i"
            :to="`/${menuItem.link}`"
            :icon="menuItem.icon"
        >
          {{ menuItem.text }}
        </SectionBtnCard>
    </v-row>
  </v-container>
</template>
<script>

import { mapGetters } from 'vuex';
import SectionBtnCard from '../components/SectionBtnCard.vue';

export default {
  name: 'Root',
  components: { SectionBtnCard },

  computed: {
    ...mapGetters({
      menuItems: 'menu/filteredMenuItems',
    }),
  },
};
</script>
